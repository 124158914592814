import attempt from './attempt';

async function postRevueRequest(email) {
  const [err, result] = await attempt(
    fetch('/api/subscribe-newsletter', {
      method: 'post',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        email: email
      })
    })
  );

  if (err) throw err;

  const json = await result.json();

  return json;
}

export default postRevueRequest;
