import '../styles/styles.css';
import dynamic from 'next/dynamic';

import Layout from '../components/Layout';
import FeaturedProjects from '../components/FeaturedProjects';
import NewsList from '../components/NewsList';
import getNewsList from '../lib/getNewsList';
import getFeaturedProjects from '../lib/getFeaturedProjects';

const Blobs = dynamic(import('../components/blobs/Blobs'));

const Index = ({ newsList, featuredProjectsList, preview }) => (
  <Layout hideFeatured={true}>
    {<Blobs />}

    <section className="page">
      <FeaturedProjects featuredProjectsList={featuredProjectsList} />
    </section>

    <NewsList newsList={newsList.json} total={newsList.total} preview={preview} />
  </Layout>
);

// This function gets called at build time on server-side.
export async function getStaticProps({ preview }) {
  const newsList = await getNewsList(preview);

  const featuredProjectsList = await getFeaturedProjects(preview);

  return {
    props: { newsList, featuredProjectsList, preview: preview || false },
    revalidate: 60
  }
}

export default Index;
