import classNames from 'classnames';
import { useState } from 'react';
import NewsletterSignup from './NewsletterSignup';
import SecondaryLink from './SecondaryLink';
import AnimateIn from './AnimateIn';
import delve from 'dlv';

import getNewsList from '../lib/getNewsList';

// Will first display all newsItems in the featuredNewsList then will show X newsItems from newsList

const NewsList = ({ newsList, cssModifier, total, perPage = 4, preview }) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages] = useState( Math.ceil( total / perPage ) );
  const [newsItems, setNewsItems] = useState(delve(newsList, 'stories', []));

  const loadMorePosts = async () => {
    if (currentPage) {
      const newNews = await getNewsList(preview, currentPage + 1, perPage);

      setNewsItems([...newsItems, ...newNews.json.stories]);

      if (window.ga) {
        ga('send', 'event', {
          eventCategory: 'News',
          eventAction: 'Load more',
          eventValue: currentPage
        });
      }

      setCurrentPage(currentPage + 1);
    }
  };

  return (
    <div
      className={classNames('news-list', cssModifier, {
        'news-list--done': currentPage == totalPages
      })}
    >
      <AnimateIn className="link-list g-row">
        <ul className="link-list__container u-no-list">
          {newsItems.map((link, index) => {
            return (
              <React.Fragment key={index}>
                {/* {currentPage >= 2 && index == perPage && (
                  <li key={`newsletter${index}`} className="u-cf fade-in">
                    <NewsletterSignup />
                  </li>
                )} */}

                <li key={`newsitem${index}`} className="link-list__item">
                  <SecondaryLink
                    title={link.name}
                    url={
                      link.content.component === 'External Post'
                        ? link.content.Link
                        : `/${link.full_slug}`
                    }
                    source={link.content.Source}
                  />
                </li>
              </React.Fragment>
            );
          })}
        </ul>
      </AnimateIn>

      {currentPage < totalPages && (
        <div className="g-row">
          <button
            className="news-list__load btn btn--colored btn--arrow"
            onClick={loadMorePosts}
          >
            Show more news
          </button>
        </div>
      )}
    </div>
  );
};

export default NewsList;
