import classNames from 'classnames';
import ActiveLink from '../lib/activeLink';

const externalRegExp = /^https?:/; //

const SecondaryLink = ({ url, title, source, active = false }) => {
  return (
    <ActiveLink activeClassName="secondary-link--active" href={url}>
      <a
        className="secondary-link"
        target={externalRegExp.test(url) ? '_blank' : undefined}
        rel={externalRegExp.test(url) ? 'noopener' : undefined}
      >
        <span className="secondary-link__title">{title}</span>
        {source && <span className="secondary-link__source">{source}</span>}
      </a>
    </ActiveLink>
  );
};

export default SecondaryLink;
