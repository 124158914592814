import StoryblokService from './storyblok-service';

const getNewsList = async (preview, page = 1, perPage = 4) => {
  const response = await StoryblokService.get(`cdn/stories`, {
    version: preview ? 'draft' : 'published',
    starts_with: 'news',
    page: page,
    per_page: perPage,
  });

  const json = response.data;
  const total = response.headers.total;

  return { json, total };
};

export default getNewsList;
